// default below 576px (Portrait Phones)
$breakpoint-sm: 576px; // Landscape Phones
$breakpoint-md: 768px; // Tablets
$breakpoint-lg: 992px; // Desktops
$breakpoint-xl: 1200px; // Large Desktops
$breakpoint-xxl: 1600px; // Extra Large Desktops
$breakpoint-vr: 600px; // Standalone VR Browsers

$transparent: transparent;
$transparent-hover: rgba(0, 0, 0, 0.08);
$transparent-pressed: rgba(0, 0, 0, 0.12);

$white: #FFFFFF;
$white-hover: #E7E7E7;
$white-pressed: #DBDBDB;

$lightgrey: #E7E7E7;
$lightgrey-hover: #F5F5F5;
$lightgrey-pressed: #DBDBDB;

$grey: #BBBBBB;
$grey-hover: #C7C7C7;
$grey-pressed: #ADADAD;

$darkgrey: #868686;
$darkgrey-hover: #949494;
$darkgrey-pressed: #7A7A7A;

$black: #000000;
$black-hover: #404040;
$black-pressed: #7A7A7A;

$red: #F5325C;
$red-hover: #F64B70;
$red-pressed: #F41A49;

$orange: #FF8500;
$orange-hover: #FF911A;
$orange-pressed: #E67800;

$green: #7ED320;
$green-hover: #8CDF2F;
$green-pressed: #72BE1D;

$blue: #007AB8;
$blue-hover: #008BD1;
$blue-pressed: #00699E;

$purple: #7854F6;
$purple-hover: #8C6EF7;
$purple-pressed: #663DF5;

$recessed-bg: #f9f9f9;

$yellow: #FFC000;

// Brand Colors
$spoke-primary-color: #2F80ED;
$twitter-primary-color: #6FC0FD;
$slack-primary-color: #611F69;
$discord--primary-color: #7289DA;

// Discord Bot Page Colors
$discord-bg-color: #2A2D32;
$discord-text1-color: white;
$discord-text2-color: #A3A3A3;
$discord-text3-color: rgb(127, 127, 127);
$discord-text4-color: rgb(64, 64, 64);

$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-2xl: 28px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$border-radius-regular: 8px;
$border-radius-small: 6px;

$outline-width: 3px;

$spacing-2xs: 4px;
$spacing-xs: 8px;
$spacing-sm: 12px;
$spacing-md: 16px;
$spacing-lg: 20px;
$spacing-xl: 24px;

// Theme SCSS Variables:
// Reference these variables in the Hubs codebase.
// The CSS variables they reference are defined in ./global.scss such that they are only included on the page once.
$font-family-default: var(--font-family-default);
$font-family-monospace: var(--font-family-monospace);

$text1-color: var(--text1-color);
$text1-color-hover: var(--text1-color-hover);
$text1-color-pressed: var(--text1-color-pressed);
$text2-color: var(--text2-color);
$text2-color-hover: var(--text2-color-hover);
$text2-color-pressed: var(--text2-color-pressed);
$text3-color: var(--text3-color);
$text3-color-hover: var(--text3-color-hover);
$text3-color-pressed: var(--text3-color-pressed);
$text4-color: var(--text4-color);
$text4-color-hover: var(--text4-color-hover);
$text4-color-pressed: var(--text4-color-pressed);
$text5-color: var(--text5-color);
$text5-color-hover: var(--text5-color-hover);
$text5-color-pressed: var(--text5-color-pressed);
$text-inverted-color: var(--text-inverted-color);

$link-color: var(--link-color);
$link-color-hover: var(--link-color-hover);
$link-color-pressed: var(--link-color-pressed);

$accept-color: var(--accept-color);
$accept-border-color: var(--accept-border-color);
$accept-color-hover: var(--accept-color-hover);
$accept-color-pressed: var(--accept-color-pressed);

$cancel-color: var(--cancel-color);
$cancel-color-hover: var(--cancel-color-hover);
$cancel-color-pressed: var(--cancel-color-pressed);

$accent1-color: var(--accent1-color);
$accent1-border-color: var(--accent1-border-color);
$accent1-color-hover: var(--accent1-color-hover);
$accent1-color-pressed: var(--accent1-color-pressed);

$accent2-color: var(--accent2-color);
$accent2-border-color: var(--accent2-border-color);
$accent2-color-hover: var(--accent2-color-hover);
$accent2-color-pressed: var(--accent2-color-pressed);

$accent3-color: var(--accent3-color);
$accent3-color-hover: var(--accent3-color-hover);
$accent3-color-pressed: var(--accent3-color-pressed);
$accent3-border-color: var(--accent3-border-color);

$accent4-color: var(--accent4-color);
$accent4-border-color: var(--accent4-border-color);
$accent4-color-hover: var(--accent4-color-hover);
$accent4-color-pressed: var(--accent4-color-pressed);

$accent5-color: var(--accent5-color);
$accent5-border-color: var(--accent5-border-color);
$accent5-color-hover: var(--accent5-color-hover);
$accent5-color-pressed: var(--accent5-color-pressed);

$accent6-color: var(--accent6-color);
$accent6-color-hover: var(--accent6-color-hover);
$accent6-color-pressed: var(--accent6-color-pressed);

$primary-color: var(--primary-color);
$primary-color-hover: var(--primary-color-hover);
$primary-color-pressed: var(--primary-color-pressed);

$secondary-color: var(--secondary-color);
$secondary-color-hover: var(--secondary-color-hover);
$secondary-color-pressed: var(--secondary-color-pressed);

$background1-color: var(--background1-color);
$background2-color: var(--background2-color);
$background3-color: var(--background3-color);
$background4-color: var(--background4-color);
$background5-color: rgba(0, 0, 0, 0);

$loading-screen-background: var(--loading-screen-background);

$border1-color: var(--border1-color);
$border2-color: var(--border2-color);
$border3-color: var(--border3-color);

$outline-color: var(--outline-color);

$shadow-color: var(--shadow-color);

$basic-color: var(--basic-color);
$basic-color-hover: var(--basic-color-hover);
$basic-color-pressed: var(--basic-color-pressed);
$basic-border-color: var(--basic-border-color);

$disabled-text-color: var(--disabled-text-color);
$disabled-bg-color: var(--disabled-bg-color);
$disabled-icon-color: var(--disabled-icon-color);

$radio-border-color: var(--radio-border-color);
$radio-bg-color: var(--radio-bg-color);
$radio-bg-color-hover: var(--radio-bg-color-hover);
$radio-bg-color-pressed: var(--radio-bg-color-pressed);

$toggle-button-color: var(--toggle-button-color);

$input-bg-color: var(--input-bg-color);
$input-icon-color: var(--input-icon-color);
$input-border-color: var(--input-border-color);
$input-border-color-hover: var(--input-border-color-hover);
$input-outline-color: var(--input-outline-color);

$list-bg-color-hover: var(--list-bg-color-hover);
$list-bg-color-pressed: var(--list-bg-color-pressed);

$dropdown-bg-color: var(--dropdown-bg-color);
$dropdown-border-color: var(--dropdown-border-color);
$dropdown-shadow-color: var(--dropdown-shadow-color);

$button-text-color: var(--button-text-color);
$button-border-color: var(--button-border-color);
$button-bg-color: var(--button-bg-color);
$button-bg-color-hover: var(--button-bg-color-hover);
$button-bg-color-pressed: var(--button-bg-color-pressed);

$active-text-color: var(--active-text-color);
$active-color: var(--active-color);
$active-color-hover: var(--active-color-hover);
$active-color-pressed: var(--active-color-pressed);

$chat-bubble-bg-color-sent: var(--chat-bubble-bg-color-sent);
$chat-bubble-text-color-sent: var(--chat-bubble-text-color-sent);
$chat-bubble-link-color-sent-hover: var(--chat-bubble-link-color-sent-hover);
$chat-bubble-link-color-sent-pressed: var(--chat-bubble-link-color-sent-pressed);
$chat-bubble-bg-color-received: var(--chat-bubble-bg-color-received);

$tip-text-color: var(--tip-text-color);
$tip-bg-color: var(--tip-bg-color);
$tip-button-color: var(--tip-button-color);
$tip-button-color-hover: var(--tip-button-color-hover);
$tip-button-color-pressed: var(--tip-button-color-pressed);

$favorite-color: var(--favorite-color);

$admin-color: var(--admin-color);

$error-color: var(--error-color);
$error-color-hover: var(--error-color-hover);
$error-color-pressed: var(--error-color-pressed);

$status-unread-color: var(--status-unread-color);
$status-recording-color: var(--status-recording-color);
$status-enabled-color: var(--status-enabled-color);
$status-disabled-color: var(--status-disabled-color);

$overlay-bg-color: var(--overlay-bg-color-new);
$overlay-text-color: var(--overlay-text-color);
$overlay-border-color: var(--overlay-border-color);
$overlay-outline-color: var(--overlay-outline-color);

$toolbar-icon-color: var(--toolbar-icon-color);
$toolbar-icon-selected-bg: var(--toolbar-icon-selected-bg);
$toolbar-basic-icon-color: var(--toolbar-basic-icon-color);
$toolbar-basic-selected-icon-color: var(--toolbar-basic-selected-icon-color);
$toolbar-basic-color: var(--toolbar-basic-color);
$toolbar-basic-color-hover: var(--toolbar-basic-color-hover);
$toolbar-basic-color-pressed: var(--toolbar-basic-color-pressed);
$toolbar-basic-border-color: var(--toolbar-basic-border-color);
$toolbar-label-accent1: var(--toolbar-label-accent1);
$toolbar-label-accent2: var(--toolbar-label-accent2);
$toolbar-label-accent3: var(--toolbar-label-accent3);
$toolbar-label-accent4: var(--toolbar-label-accent4);
$toolbar-label-accent5: var(--toolbar-label-accent5);

$tile-text-color: var(--tile-text-color);
$tile-bg-color: var(--tile-bg-color);
$tile-bg-color-hover: var(--tile-bg-color-hover);
$tile-bg-color-pressed: var(--tile-bg-color-pressed);
$tile-button-text-color: var(--tile-button-text-color);
$tile-button-bg-color: var(--tile-button-bg-color);
$tile-button-bg-color-hover: var(--tile-button-bg-color-hover);
$tile-button-bg-color-pressed: var(--tile-button-bg-color-pressed);
$tile-button-border-color: var(--tile-button-border-color);